import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Common/Layout";
import WorkPageAbout from "../../components/Website/WorkPageAbout";
import AllWork from "../../components/Website/OurWork/AllWork";
import TrustUsWorkPage from "../../components/Website/TrustUs/TrustUsWorkPage";

const Work = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <Layout
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      selectedLanguage="en"
      location="work"
      title="Brocode Studio || Our Work Page"
      description="A sample of the work we delivered to client all over the world"
    >
      <WorkPageAbout selectedLanguage="en" />
      <AllWork setModalVisible={setModalVisible} selectedLanguage="en" />
      <TrustUsWorkPage
        setModalVisible={setModalVisible}
        selectedLanguage="en"
      />
    </Layout>
  );
};

export default Work;
